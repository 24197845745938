.tablecontent {
  margin-left: 1%;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: #284783;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bolder;
  font-size: 20px;
  line-height: 25px;
  background-color: white;
}
th,
td {
  text-align: center;
  padding: 24px;
}

tr {
  border-bottom: 1px solid red;
}

.no-res {
  color: red;
  width: 100%;
  margin-left: 150%;
  font-size: 30px;
  font-weight: 600;
}
#tbody {
  background-color: white;
}
/* #tbody tr:nth-child(even){background-color: #f2f2f2; 
  border-radius: 10px;
}
#tbody tr:nth-child(even){background-color: white;} */
