/* .username {
  display: flex;
  float: right;
  margin: -40px 30px;
}

.userid {
  height: 25px;
  width: 165px;
  margin: 55px 0px 0px 31px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  padding-top: 5px;
}

.header {
  width: 100%;
  height: 80px;
  border-bottom: #92c6bb solid 3px;
  margin-top: -20px;
} */
.dropbtn {
  color: black;
  padding-left: 10px;
  
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  background-color: lightgrey;
  min-width: 200px;
  display: none;
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.popuptext {
  background-color: rgb(235, 227, 227);
  width: 600px;
  height: 200px;
  text-align: center;
  padding-top: 10%;
  
  margin-top: 80%;
  display: inline-block;
  opacity: 1;
  border-radius: 2%;
  position: relative;
  top: 40%;
  right: 140%;
  border: 1px solid black;
}
.logout-btn {
  margin: 5%;
  width: 20%;
  height: 5vh;
  border-radius: 20px;
  background-color: rgb(233, 233, 247);
}

.header {display: flex;height: 17vh;width: 80vw;margin-bottom: 4vh;border-bottom: 3px solid #b1cbc6;}


.userid {
    display: flex;
    padding: 7vh;
    font-size: 20px;
}

.username {
    display: flex;
    padding: 6.5vh 0vh 0vh 90vh;
    font-size: 20px;
}



.\31 {
    padding-left: .5vw;
}
