* {
  font-family: Source-Sans-Pro;
  font-size: 1.2vw;
}

.App {
  width: 100vw;

  display: flex;
}

body {
  margin: 0;
  padding: 0;
}
button.bnt {
  width: 180px;
  font-size: 15px;
  margin-left: 100px;
  border-radius: 20px;
  height: 40px;
}
