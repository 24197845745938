.midle {
  width: 100%;
  background-color: aliceblue;
}
.stages {
  height: 100px;
}

.head {
  margin: 20px;
  font-size: 22px;
}
input.text {
  width: 500px;
  height: 50px;
  border-radius: 5px;
  padding-left: 10px;
}

form.form {
  width: 95%;
  background: white;
  margin-left: 30px;
}
.content-1 {
  display: flex;
}

.col1 {
  /* margin: 30px; */
  margin-left: 60px;
}

.col2 {
  margin-left: 60px;
}
.row {
  margin: 20px 0px 20px 0px;
}

div {
  margin-bottom: 5px;
}

button.prevoius {
}

a {
  /* margin-left: 300px; */
}

.footer {
  text-align: center;
  padding-left: 200px;
}

button.prevoius {
  width: 180px;
  height: 50px;
}

button.btn1 {
  width: 187px;
  height: 50px;
  border-radius: 20px;
  background: #6ab4f8;
  color: white;
}

button.btn2 {
  width: 187px;
  height: 50px;
  border-radius: 20px;

  margin-left: 40px;
  background-color: #4c57b6;
  color: white;
}
.camlogo {
  display: flex;
  /* width: 200px; */
  margin: 0px 0px 0px;
  margin: 0px 0px 30px 60px;
}

.cameraimg {
  width: 90px;
  height: 90px;
}

.addphoto {
  margin: 40px;
  font-weight: bold;
}
.camlogo {
  display: flex;
  /* width: 200px; */
  margin: 0px 0px 0px;
  margin: 0px 0px 30px 60px;
}

.addphoto {
  margin: 40px;
  font-weight: bold;
}
select.text {
  width: 516px;
  height: 50px;
  border-radius: 5px;
  padding-left: 10px;
}
