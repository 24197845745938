.Search {
  margin: 20px 0px 10px 50px;
}

button.add-user {
  float: right;
  height: 50px;
  border-radius: 50px;
  width: 180px;
  background-color: #6ab4f8;
  color: white;
  font-size: 19px;
  border: 2px solid white;
}
form.search-form {
  margin-bottom: 40px;
}
form.search-form {
  width: 95%;
}

input#search {
  height: 50px;
  width: 300px;
  border-radius: 50px;
  padding-left: 20px;
  font-size: 20px;
  border: 2px solid white;
}
